<template>
  <!-- <b-row class="equipe-view full-width-row"> -->
  <b-row class="equipe-view">
    <div v-if="members">
      <master-title>L'équipe</master-title>
      <b-row class="members-row" v-for="(memberGroup, idx) in members" :key="idx">
        <master-title-2>{{ memberGroup.name }}</master-title-2>
        <div class="members">
          <div class="member-card" v-for="(member, idx2) in memberGroup.members" :key="idx2">
            <div class="member-image" v-bind:style="{ backgroundImage: 'url(' + member.image_url + ')' }">
              <div class="member-name">
                <div><b>{{ member.name }}</b></div>
                <div>{{ member.role }}</div>
              </div>
            </div>
          </div>
        </div>
      </b-row>
    </div>
  </b-row>
</template>

<script>
import MasterTitle from '@/components/utils/MasterTitle.vue'
import MasterTitle2 from '@/components/utils/MasterTitle2.vue'
import { api_call } from '@/plugins/utils.js'
import Store from '@/store/index.js'

export default {
  components: { MasterTitle, MasterTitle2 },
  name: "Equipe",
  data() {
    return {
      members: [],
    }
  },
  mounted() {
    var max_width = parseFloat(getComputedStyle(document.documentElement).fontSize) * 12  // max image width is 12 rem
    api_call()
      .get("/members")
      .then(response => {
        var members = response.data;
        for (var memberGroup of members) {
          for (var member of memberGroup.members) {
            member.image_url = Store.state.api_url + "/members/image?photo=" + member.photo + '&max_width=' + max_width;
          }
        }
        this.members = members;
      })
  }
}
</script>

<style scoped>
.equipe-view {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  /* background: linear-gradient(160deg, #ffffff 0%, #ffffff 30%, var(--color-grey-light) 30%, var(--color-grey-light) 100%); */
}

.members-row {
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.members {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
}

.member-card {
  display: flex;
  flex-direction: column;
  margin: 0.3rem;
  padding: 1%;
  width: 12rem;
  height: 16rem;
  background-color: rgba(255, 255, 255, 0.144);
}
@media only screen and (max-width: 768px) {  /* phones only */
  .member-card {
    width: 9rem;
    height: 12rem;
  }
}
.member-card:hover {
  background-color: rgba(190, 190, 190, 0.445);
}

.member-image {
  min-height: 100%;
  max-height: 100%;
  min-width: 100%;
  max-width: 100%;
  background-position: 50% 50%;
  background-size: cover;
  align-content: flex-end;
  position: relative;
}

.member-name {
  color: #000;
  display: flex;
  min-height: 15%;
  max-height: 40%;
  min-width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  flex: 0 auto;
  border-radius: 0px;
  background-color: rgb(193 193 193 / 56%);
  font-size: 15px;
  text-align: center;
  line-height: 15px;

  position: absolute;
  bottom: 0;
  left: 0;
}

</style>