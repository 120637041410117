<template>
  <b-row class="objectifs-view full-width-row">
    <master-title>Nos objectifs</master-title>
    <p class="tb-objectif large-text">Proposer une offre alimentaire saine et responsable sur les campus via notre food truck étudiant.</p>
    <p class="tb-objectif large-text">Donner les outils aux étudiant.e.s pour les accompagner dans leur transition alimentaire.</p>

    <div class="legumes-line" v-on:click="start_route()">
      <legume v2 legume="abricot" height="2rem" orientation="0deg" />
      <legume v2 legume="aubergine" height="2rem" orientation="0deg" />
      <legume v2 legume="brocoli" height="2rem" orientation="0deg" />
      <legume v2 legume="carotte" height="2rem" orientation="0deg" />
      <legume v2 legume="citrouille" height="2rem" orientation="0deg" />
      <legume v2 legume="cornichon" height="2rem" orientation="0deg" />
      <legume v2 legume="noix" height="2rem" orientation="0deg" />
      <legume v2 legume="oignon" height="2rem" orientation="0deg" />
      <legume v2 legume="poivron" height="2rem" orientation="0deg" />
      <legume v2 legume="pomme" height="2rem" orientation="0deg" />
      <legume v2 legume="tomate" height="2rem" orientation="0deg" />
    </div>

    <master-title>Nos projets</master-title>
  </b-row>
</template>

<script>
import Legume from '@/components/utils/Legume.vue'
import MasterTitle from '@/components/utils/MasterTitle.vue'
export default {
  components: { MasterTitle, Legume },
  name: "Objectifs",
  methods: {
    start_route() {
      window.location.href = "/la-route";
    },
  },
}
</script>

<style scoped>
.objectifs-view {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: linear-gradient(20deg, #ffffff 0%, #ffffff 30%, var(--color-beige-light) 30%, var(--color-beige-light) 100%);
}

.tb-objectif {
  margin-bottom: 3rem;
}
</style>