<template>
  <b-container>
    <b-row class="justify-content-md-center">
      <b-col lg="10" align="center">
        <h1 class="tb-title">Contact</h1>
        <p>Une question pratique par rapport au food truck : <a href="mailto:foodtruck@timbastien.fr">foodtruck@timbastien.fr</a></p>
        <p>Contacter le bureau de l'association : <a href="mailto:contact@timbastien.fr">contact@timbastien.fr</a></p>
        <p>Numéro de téléphone Tim & Bastien : <a style="text-decoration:none" href="tel:+33744404184">07 44 40 41 84</a></p>
        <p>Numéro de téléphone du food truck : <a style="text-decoration:none" href="tel:+33752060604">07 52 06 06 04</a></p>
        <p>S'abonner a la newsletter : <a style="text-decoration:none" href="/login">ici</a></p>
        <p>Numéro de siret Tim & Bastien : 89498246100036</p>
        <b-button variant="outline" href="https://www.facebook.com/timetbastien" target="_blank"><b-icon font-scale="2" icon="facebook"></b-icon></b-button>
        <b-button variant="outline" href="https://www.instagram.com/timetbastien" target="_blank"><b-icon font-scale="2" icon="instagram"></b-icon></b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { is_phone } from '@/plugins/utils.js'

export default {
  name: 'Contact',
  data () {
    return {
      is_phone: is_phone,
    }
  },
  created () {
    window.addEventListener("resize", this.handleResize);
  },
  destroyed () {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize () {
      this.$forceUpdate()
    },
  },
}
</script>

<style>
</style>
