<template>
  <b-container>
    <b-row class="justify-content-md-center">
      <b-col lg=10 align="center">
        <h1 class="tb-title">Nos principaux partenaires</h1>

        <div class="partenaire-desc-right">
          <img src="assets/partenaires/Logo France Relance 2.png" />
          <div class="space" />
          <div class="desc">
            <h1 class=tb-title-2>France relance</h1>
            <p class="tb-content">Soutenu par le plan France Relance dans le département de la Meurthe-et-Moselle (Alimentation locale et solidaire).</p>
          </div>
        </div>
        <hr/>

        <!-- <div class="partenaire-desc-left">
          <img src="assets/partenaires/Logo DRAAF 2.png" />
          <div class="space" />
          <div class="desc">
            <h1 class=tb-title-2>DRAAF</h1>
            <p class="tb-content">Programme national pour l'alimentation 2019-2023 : territoires en action</p>
          </div>
        </div>
        <hr/> -->

        <div class="partenaire-desc-left">
          <img src="assets/partenaires/Logo ARS.png" />
          <div class="space" />
          <div class="desc">
            <h1 class=tb-title-2>ARS</h1>
            <p class="tb-content">Financement de l'Agence Régionale de Santé Grand Est</p>
          </div>
        </div>
        <hr/>

        <div class="partenaire-desc-right">
          <img src="assets/partenaires/Logo UL.png" />
          <div class="space" />
          <div class="desc">
            <h1 class=tb-title-2>Université de Lorraine</h1>
            <p class="tb-content">Notre partenaire principal dans le projet. En plus de nous subventionner, l'UL permet à notre food truck de silloner ses campus.</p>
          </div>
        </div>
        <hr/>

        <div class="partenaire-desc-left">
          <img src="assets/partenaires/Logo Metropole grand nancy.png" />
          <div class="space" />
          <div class="desc">
            <h1 class=tb-title-2>Métropole du Grand Nancy</h1>
            <p class="tb-content">Financement dans le cadre du financement de la Métropole du Grand Nancy au côté de l'Etat pour un plan de Lutte contre la Pauvreté et l'accés à l'Emploi 2020 - 2022.</p>
          </div>
        </div>
        <hr/>

        <div class="partenaire-desc-right">
          <img src="assets/partenaires/Logo FDVA grand est.png" />
          <div class="space" />
          <div class="desc">
            <h1 class=tb-title-2>FDVA grand est</h1>
            <p class="tb-content">Soutien financier de la préfecture de région au titre du Fond de développement de la vie associative.</p>
          </div>
        </div>
        <hr/>

        <div class="partenaire-desc-left">
          <img src="assets/partenaires/Logo fondation Auchan.png" />
          <div class="space" />
          <div class="desc">
            <h1 class=tb-title-2>Fondation Auchan</h1>
            <p class="tb-content">La fondation Auchan soutient notre initiative en participant grandement dans les frais d'investissement du food truck.</p>
          </div>
        </div>
        <hr/>

        <!-- <div class="partenaire-desc-left">
          <img src="assets/partenaires/Logo Nancy.png" />
          <div class="space" />
          <div class="desc">
            <h1 class=tb-title-2>Ville de Nancy</h1>
            <p class="tb-content">La ville de Nancy nous a subventionnés grâce au plan "Ma ville, Mon projet"</p>
          </div>
        </div>
        <hr/>

        <div class="partenaire-desc-right">
          <img src="assets/partenaires/Logo montigny.png" />
          <div class="space" />
          <div class="desc">
            <h1 class=tb-title-2>Ville de Montigny-le-Bretonneux</h1>
            <p class="tb-content">La ville de Montigny-le-Bretonneux nous a subventionnés grâce à sa bourse aux projets pour les jeunes.</p>
          </div>
        </div> -->
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { is_phone } from '@/plugins/utils.js'

export default {
  name: 'Nos_partenaires',
  components: {
  },
  data () {
    return {
      is_phone: is_phone,
    }
  },
  mounted () {
  },
}
</script>

<style scoped>
div[class^="partenaire-desc-"] {
  display: flex;
  justify-content: flex-start;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
div[class^="partenaire-desc-"] img {
  max-height: 10rem;
  max-width: 50%;
  object-fit: contain;
}
div[class^="partenaire-desc-"] .space {
  height: 100%;
  width: 2rem;
}
div[class^="partenaire-desc-"] .desc {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.partenaire-desc-left {
  flex-direction: row;
}
.partenaire-desc-left .desc {
  justify-content: flex-start;
}
.partenaire-desc-left .desc p {
  text-align: left;
}

.partenaire-desc-right {
  flex-direction: row-reverse;
}
.partenaire-desc-right .desc {
  justify-content: flex-end;
}
.partenaire-desc-right .desc p {
  text-align: left;
}
</style>
