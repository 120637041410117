<template>
  <b-container fluid="sm" id="projet-page">
    <accueil />
    <objectifs />
    <projets />
    <equipe />
    <et-vous />
    <partenaires />
    <tb-footer />
  </b-container>
</template>

<script>
import Accueil from '@/components/projet/Accueil.vue'
import Objectifs from '@/components/projet/Objectifs.vue'
import Projets from '@/components/projet/Projets.vue'
import Equipe from '@/components/projet/Equipe.vue'
import EtVous from '@/components/projet/EtVous.vue'
import TbFooter from '@/components/utils/TbFooter.vue'
import Partenaires from '@/components/projet/Partenaires.vue'

export default {
  name: "Le_projet",
  components: {
    Accueil,
    Objectifs,
    Projets,
    Equipe,
    EtVous,
    TbFooter,
    Partenaires
  }
}
</script>

<style scoped>
#projet-page {
  display: flex;
  flex-direction: column;
  justify-content: top;
}
</style>