<template>
  <div>
    <b-container v-if="cookie">
      <b-row class="le-menu-row">
        <b-col v-if="act_menu" align="center">
          <h1 class="carte-menu-title">Plat du jour</h1>
          <div class="today-plat-du-jour"><a :href="'/recettes/' + act_menu.recipe.id">{{ act_menu.recipe.name }}</a> - <a :href="'/recettes/' + act_menu.dessert.id">{{ act_menu.dessert.name }}</a></div>
          <!-- <b-button variant="secondary" size="lg" :href="'/recettes/' + act_menu.recipe.id">{{ act_menu.recipe.name }}</b-button> -->
          <div class="today-recipe-images">
            <a v-bind:style="{ backgroundImage: 'url(' + act_menu.recipe.image_url + ')' }" :href="'/recettes/' + act_menu.recipe.id" />
            <a v-bind:style="{ backgroundImage: 'url(' + act_menu.dessert.image_url + ')' }" :href="'/recettes/' + act_menu.dessert.id" />
          </div>
        </b-col>
        <b-col v-else>
          <h1 class="carte-menu-title">Le food truck est fermé aujourd'hui</h1>
        </b-col>
      </b-row>

      <!-- <b-row class="justify-content-md-center le-menu-row">
        <b-col lg=6>
          <h1 class="carte-menu-title">Menu</h1>
          <p class="tb-text-content" style="text-align: center;">Chaque légume représente un tarif, indique nous celui qui te convient le mieux en fonction de tes moyens ou de ce que tu veux donner à l'association</p>
          <img class="systeme-paiement-image" src="@/../public/assets/systeme_paiement.png">
        </b-col>
      </b-row> -->
      
      <b-row class="justify-content-md-center le-menu-row">
        <b-col lg=6>
          <h1 class="carte-menu-title">Menu</h1>
          <div class="carte-menu-plat-title">Plat du jour + Dessert</div>
          <div class="carte-menu-info">Chaque jour un plat unique cuisiné le matin avec un maximum de produits bio et locaux.</div>
          <div class="carte-menu-elements">
            <div class="carte-flex"><div class="carte-menu-plat-type">étudiant boursier</div><div class="carte-menu-price"><div class="price-star">*</div>1€</div></div>
            <div class="carte-flex"><div class="carte-menu-plat-type">étudiant non boursier</div><div class="carte-menu-price"><div class="price-star">*</div>3,30€</div></div>
            <div class="carte-flex"><div class="carte-menu-plat-type">non étudiant</div><div class="carte-menu-price"><div class="price-star">*</div>5,80€</div></div>
          </div>
          <div class="carte-menu-tup"><div class="price-star">*</div><b>+30cts</b>&nbsp;si vous n'avez pas de tup'</div>
        </b-col>
      </b-row>

      <b-row class="justify-content-md-center le-menu-row">
        <b-col lg=6>
          <h1 class="carte-menu-title">Extras</h1>
          <div class="carte-menu-elements">
            <div class="carte-flex"><div class="carte-menu-extra-type">Café</div><div class="carte-menu-price">GRATUIT</div></div>
            <div class="carte-menu-info">Un délicieux café bio et commerce équitable (Plus d'info <a href="https://cafesati.com/" target="_blank">ici</a>).</div>
            <div class="carte-flex"><div class="carte-menu-extra-type">Pâtisserie</div><div class="carte-menu-price">1€</div></div>
            <div class="carte-menu-info">De délicieuses pâtisseries faites maison.</div>
            <div class="carte-flex"><div class="carte-menu-extra-type">Jus de fruits</div><div class="carte-menu-price">0,80€</div></div>
            <div class="carte-menu-info">Un jus de fruits bio et local (Plus d'info <a href="https://pierroteric.site-solocal.com/" target="_blank">ici</a>).</div>
          </div>
        </b-col>
      </b-row>
      <b-row v-if="act_destination" class="le-menu-row">
        <b-col>
            <h1 class="carte-menu-title">Où sommes-nous ?</h1>
            <div class="destination-and-date">
              <div>
                <p class="date">{{ act_destination.campus_name }}</p>
                <p class="address">Du {{ act_destination.start_date_str }} au {{ act_destination.end_date_str }}<br>{{ act_destination.campus_address_street }}, {{ act_destination.campus_address_postcode }} {{ act_destination.campus_address_city }}</p>
              </div>
              <div>
                <p class="align-center"><b>Horaires du food truck</b></p>
                <p class="align-center"><span v-html="horraires" /></p>
              </div>
            </div>
            <div v-for="(campus, key) of next_destinations" :key="key">
              <p class="align-center"><b>{{ campus.campus_name }}</b> : {{ campus.start_date_str }} - {{ campus.end_date_str }}</p>
            </div>
            <p class="anciennes-destinations"><a href="/destinations">Voir les anciennes destinations du food truck</a></p>
        </b-col>
      </b-row>
      <b-row class="le-menu-row" v-if="nexts_menus && nexts_menus.length > 0">
        <b-col align="center">
          <h1 class="carte-menu-title">Menus des prochains jours</h1>
          <div v-for="(menu, menu_idx) in nexts_menus" :key="menu_idx">
            <p class="prochaines-recettes">{{ menu.print_date }} : <a :href="'/recettes/' + menu.recipe.id">{{ menu.recipe.name }}</a> - <a :href="'/recettes/' + menu.dessert.id">{{ menu.dessert.name }}</a></p>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <div style="width: 100%; height: 30px;"></div>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { api_call, str_to_date, date_to_long_str, get_image_url, capitalize } from '@/plugins/utils.js'

export default {
  name: 'Le_menu',
  components: {
  },
  data () {
    return {
      horraires: null,
      destinations: null,
      act_destination: null,
      next_destinations: null,
      act_menu: null,
      cookie: null,
      nexts_menus: null,
    }
  },
  mounted () {
    api_call()
      .get("/data/public/destinations_horraires")
      .then(response => {
        this.horraires = response.data.value.replaceAll("\n", "<br>")
      })
    api_call()
      .get("/destinations?published_only=true")
      .then(response => {
        this.destinations = response.data
        this.next_destinations = []
        for (var dest of this.destinations) {
          dest.start_date_str = date_to_long_str(str_to_date(dest.start_date))
          dest.end_date_str = date_to_long_str(str_to_date(dest.end_date))
          if (str_to_date(dest.start_date) > Date.now()) {
            this.next_destinations.push(dest)
          }
        }
      })
    api_call()
      .get("/destinations/actual")
      .then(response => {
        this.act_destination = response.data
        this.act_destination.start_date_str = date_to_long_str(str_to_date(this.act_destination.start_date))
        this.act_destination.end_date_str = date_to_long_str(str_to_date(this.act_destination.end_date))
      })
    api_call()
      .get("/menus/actual")
      .then(response => {
        this.act_menu = response.data
        this.act_menu.recipe.image_url = get_image_url("/recipes", this.act_menu.recipe.id)
        this.act_menu.dessert.image_url = get_image_url("/recipes", this.act_menu.dessert.id)
      })
    api_call()
      .post("/recipes/search", {
        search: {
            search_data: "Cookies duo de chocolat",
            published_only: true,
        }})
      .then(response => {
        this.cookie = {
          id: response.data[0].id,
          name: "Cookie",
          image_url: get_image_url("/recipes", response.data[0].id),
        }
      })
      .catch(error => {
        this.cookie = {
          id: -1,
          name: "",
          image_url: get_image_url("/recipes", -1),
        }
      })
    api_call()
      .get("/menus?discard_old=true&discard_today=true")
      .then(response => {
        this.nexts_menus = response.data
        for (const [key, obj] of Object.entries(this.nexts_menus)) {
          obj.date = str_to_date(obj.date)
          obj.print_date = capitalize(obj.date.toLocaleString('fr-FR', { weekday: 'long' })) + " " + obj.date.getDate() + " " + obj.date.toLocaleString('fr-FR', { month: 'long' })
        }
      })
  },
}
</script>

<style scoped>
.systeme-paiement-image {
  width: 100%;
}

.le-menu-row {
  margin-bottom: 20px;
}

.today-plat-du-jour-title {
  font-family: "Apple Chancery";
  font-weight: normal;
  color: #505050;
}
.today-plat-du-jour {
  font-size: 1.5rem;
}
.today-plat-du-jour a {
  font-weight: bold;
  color: var(--font-color);
  text-decoration: none;
}
.today-plat-du-jour a:hover {
  color: black;
}
.today-recipe-images {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 50vh;
  flex-wrap: wrap;
}
.today-recipe-images>* {
  width: 49.6%;
  height: 100%;
  background-position: 50% 50%;
  background-size: cover;
  margin: 0.2%;
}
@media only screen and (max-width: 768px) {  /* phones only */
  .today-recipe-images {
    width: 100%;
    height: 70vh;
    flex-wrap: wrap;
  }
  .today-recipe-images>* {
    width: 100%;
    height: 50%;
  }
}

.carte-menu-elements {
  display: flex;
  flex-direction: column;
}
.carte-menu-elements .carte-flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.carte-menu-title {
  font-family: "Apple Chancery";
  font-weight: normal;
  color: #505050;
  text-align: center;
}
.carte-menu-plat-title {
  font-size: 1.5rem;
  font-weight: bold;
}
.carte-menu-plat-type {
  font-size: 1.2rem;
}
.carte-menu-extra-type {
  font-size: 1.5rem;
  font-weight: bold;
}
.carte-menu-tup {
  display: flex;
  justify-content: right;
  align-items: flex-end;
  font-size: 0.9rem;
}
.carte-menu-tup b {
  font-size: 1.1rem;
}
.carte-menu-info {
  font-size: 1rem;
  color: #505050;
  font-style: italic;
}

.carte-menu-price {
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--font-color);
  display: flex;
}
.price-star {
  align-self: flex-start;
  font-size: 1rem;
  font-weight: initial;
  color: #838383;
}

.destination-and-date {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.destination-and-date div {
  margin-left: 10px;
  margin-right: 10px;
}
.date {
  text-align: center;
  font-weight: bold;
}
.address {
  text-align: center;
}
.anciennes-destinations {
  text-align: center;
}
.anciennes-destinations a {
  font-weight: bold;
  color: var(--font-color);
  text-decoration: none;
}
.anciennes-destinations a:hover {
  color: black;
}

.prochaines-recettes {
  font-size: 1rem;
}
.prochaines-recettes a {
  font-weight: bold;
  color: var(--font-color);
  text-decoration: none;
}
.prochaines-recettes a:hover {
  color: black;
}
</style>
