<template>
  <div class="master-title">
    <h1><slot /></h1>
  </div>
</template>

<script>
export default {
  name: "MasterTitle",
};
</script>

<style scoped>
.master-title>h1 {
  margin: 2rem;
  text-align: center;
  text-decoration-line: underline;
  text-decoration-color: var(--color-green-dark);  
  text-decoration-style: solid;
  text-decoration-skip-ink: initial;
  text-decoration-thickness: 5px; 
}

</style>