<template>
  <b-container>
    <b-row>
      <b-table striped hover
        :items="users"
        :fields="fields"
        label-sort-asc=""
        label-sort-desc=""
        label-sort-clear=""
        sort-by="is_admin"
        :sort-desc="true"
      ></b-table>
    </b-row>
  </b-container>
</template>

<script>
import { api_call, date_to_long_str, str_to_date } from '@/plugins/utils.js'

export default {
  name: 'AdminUserList',
  data() {
    return {
      fields: [
        {
          key: 'firstname',
          label: 'Prénom',
          sortable: true
        },
        {
          key: 'lastname',
          label: 'Nom',
          sortable: true
        },
        {
          key: 'username',
          label: 'Mail',
          sortable: true
        },
        {
          key: 'is_admin',
          label: 'Administateur',
          sortable: true,
        },
        {
          key: 'created_date_str',
          label: 'Membre depuis',
          sortable: true,
        },
      ],
      users: null,
    }
  },
  mounted() {
    api_call()
      .get("/login")
      .then(response => {
        this.users = response.data
        for (var user of this.users) {
          user.created_date_str = date_to_long_str(str_to_date(user.created_date_str))
        }
      })
  },
}
</script>