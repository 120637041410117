<template>
  <b-row id="et-vous-row">
    <b-col>
      <master-title>Et vous ?</master-title>
      <div id="et-vous-btns">
        <b-button href="/recettes">
          <font-awesome-icon
            style="color: var(--color-beige-base);"
            size="2x"
            icon="fa-solid fa-cookie-bite"
          />
          Testez nos délicieuses recettes
        </b-button>
        <b-button href="/faire-un-don">
          <font-awesome-icon
            style="color: var(--color-green-dark);"
            size="2x"
            icon="fa-solid fa-coins"
          />
          Faites un don au projet
        </b-button>
        <b-button href="/nous-recrutons">
          <font-awesome-icon
            style="color: #FC9B37;"
            size="2x"
            icon="fa-solid fa-users"
          />
          Devenez salarié ou bénévole chez Tim & Bastien
        </b-button>
        <b-button href="/login">
          <font-awesome-icon
            style="color: rgb(148 104 209);"
            size="2x"
            icon="fa-solid fa-envelope"
          />
          Abonnez-vous à notre newsletter
        </b-button>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCookieBite, faCoins, faUsers, faEnvelope } from '@fortawesome/free-solid-svg-icons'

library.add(faCookieBite)
library.add(faCoins)
library.add(faUsers)
library.add(faEnvelope)

import MasterTitle from '../utils/MasterTitle.vue'
export default {
  components: { MasterTitle },
  name: "EtVous",
}
</script>

<style scoped>
#et-vous-btns {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 2rem;
  margin-bottom: 5rem;
}

#et-vous-btns>* {  /* buttons */
  background-color: var(--color-brown-dark);
  color: var(--color-brown-white);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin: 1rem;
  width: 15rem;
  min-height: 8rem;
  border-radius: 2rem;
  box-shadow: 0.2rem 0.1rem 1rem var(--color-brown-dark);
}
#et-vous-btns>*:hover {  /* buttons */
  box-shadow: 0.2rem 0.1rem 2rem var(--color-brown-dark);
}
#et-vous-btns>*:active {
  transform: translateY(4px);
}
</style>