<template>
  <fragment>
    <image-and-text-row
      :image="require('@/../public/assets/photos/new_food_truck.jpg')"
      href="/le-food-truck"
      color-reverse
    >
      <h1>Le food truck</h1>
      <p>Depuis septembre 2021, nous avons ouvert un food truck tenu par des étudiants, et à destination des étudiants.</p>
      <p>Dans ce food truck, nous cuisinons de délicieux plats où les légumes frais, locaux et bio sont mis en avant. Pour permettre à l'étudiant.e de refaire nos plats chez lui, toutes nos recettes sont disponibles.</p>
    </image-and-text-row>
    <!-- <image-and-text-row
      :image="require('@/../public/assets/photos/les_panneaux.jpg')"
      href="/le-food-truck"
      reverse
    >
      <h1>L'autre projet</h1>
      <p>On a d'autres projets principaux qu'on veux mettre en avant ?</p>
    </image-and-text-row> -->
    <image-and-text-row
      :image="require('@/../public/assets/photos/coupage_carotte.jpg')"
      reverse
      no-link
    >
      <stats />
    </image-and-text-row>
  </fragment>
</template>

<script>
import ImageAndTextRow from '../utils/ImageAndTextRow.vue'
import Stats from '@/components/projet/Stats.vue'

export default {
  components: {
    ImageAndTextRow,
    Stats,
  },
  name: "Projets",
}
</script>

<style scoped>

</style>