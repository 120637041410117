<template>
  <b-container>
    <b-row class="justify-content-md-center">
      <b-col lg=10 align="center">
        <h1 class="tb-title">Faire un don</h1>
        <p>Faire un don à l'association Tim et Bastien, c'est nous permettre de développer le projet pour toucher plus d'étudiant·es. Grâce à notre food truck ouvert depuis le 20 septembre 2022, nous offrons une alimentation saine et responsable à 50 étudiant.e.s en moyenne par jour. Mais nous pensons qu'il faudrait faire plus !</p>
        <p>A terme, nous voulons que tou.te.s les étudiant.e.s aient accès à une alimentation saine basée sur des légumes Bio et locaux</p>
        <p>L'association Tim & Bastien est reconnue d'intérêt générale. A ce titre, elle peut émettre des reçus fiscaux permettant aux donateurs.rices de bénéficier d'une réduction d'impôt de 66% de la valeur du don.</p>
        <b-button variant="outline-secondary" size="lg" href="https://www.helloasso.com/associations/tim-et-bastien/formulaires/1" target="_blank">Faire un don</b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { is_phone } from '@/plugins/utils.js'

export default {
  name: 'Faire_un_don',
  data () {
    return {
      is_phone: is_phone,
    }
  },
  mounted () {
  },
}
</script>

<style>
</style>
