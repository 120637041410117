import Vue from 'vue'
import VueRouter from 'vue-router'

import Le_projet from '@/views/projet/Le_projet.vue'
import Route_page from '@/views/projet/Route_page.vue'

import Login from '@/views/login/Login.vue'
import AccountCreated from '@/views/login/AccountCreated.vue'

import Ou_sommes_nous from '@/views/foodtruck/Ou_sommes_nous.vue'
import Le_food_truck from '@/views/foodtruck/Le_food_truck.vue'
import Le_menu from '@/views/foodtruck/Le_menu.vue'
import Nos_recettes from '@/views/foodtruck/Nos_recettes.vue'
import Nos_recettes_view from '@/views/foodtruck/Nos_recettes_view.vue'

import Notre_histoire from '@/views/association/Notre_histoire.vue'
import Ils_parlent_de_nous from '@/views/association/Ils_parlent_de_nous.vue'
import Faire_un_don from '@/views/association/Faire_un_don.vue'
import Nos_partenaires from '@/views/association/Nos_partenaires.vue'
import Documents from '@/views/association/Documents.vue'

import Accompagnement_projet from '@/views/engagement/Accompagnement_projet.vue'
import Devenir_adherent from '@/views/engagement/Devenir_adherent.vue'
import Nous_recrutons from '@/views/engagement/Nous_recrutons.vue'


import Admin from '@/views/admin/Admin.vue'
import AdminCompte from '@/views/admin/AdminCompte.vue'
import AdminDestinations from '@/views/admin/AdminDestinations.vue'
import AdminMedia from '@/views/admin/AdminMedia.vue'
import AdminRecettes from '@/views/admin/AdminRecettes.vue'
import AdminRecettesEdit from '@/views/admin/AdminRecettesEdit.vue'
import AdminMenus from '@/views/admin/AdminMenus.vue'
import AdminListeCoursesEdit from '@/views/admin/AdminListeCoursesEdit.vue'
import AdminListeCourses from '@/views/admin/AdminListeCourses.vue'
import AdminUserList from '@/views/admin/AdminUserList.vue'

import Contact from '@/views/Contact.vue'
import MentionsLegales from '@/views/MentionsLegales.vue'
import CGU from '@/views/CGU.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Le projet',
    component: Le_projet
  },
  {
    path: '/la-route',
    name: 'La route',
    component: Route_page
  },
  {
    path: '/les-destinations-du-food-truck',
    name: 'Où sommes nous',
    component: Ou_sommes_nous
  },
  {
    path: '/destinations',
    name: 'Où sommes nous',
    component: Ou_sommes_nous
  },
  {
    path: '/le-food-truck',
    name: 'Le Food Truck',
    component: Le_food_truck
  },
  {
    path: '/le-menu',
    name: 'Le menu',
    component: Le_menu
  },
  {
    path: '/les-recettes-du-food-truck',
    name: 'Nos_recettes',
    component: Nos_recettes
  },
  {
    path: '/les-recettes-du-food-truck/:id',
    name: 'Nos_recettes_view',
    component: Nos_recettes_view
  },
  {
    path: '/recettes',
    name: 'Nos_recettes',
    component: Nos_recettes
  },
  {
    path: '/recettes/:id',
    name: 'Nos_recettes_view',
    component: Nos_recettes_view
  },
  {
    path: '/devenir-adherent',
    name: 'Devenir adhérent',
    component: Devenir_adherent
  },
  {
    path: '/ils-parlent-de-nous',
    name: 'Ils parlent de nous',
    component: Ils_parlent_de_nous
  },
  {
    path: '/nos-partenaires',
    name: 'Nos partenaires',
    component: Nos_partenaires
  },
  {
    path: '/notre-histoire',
    name: 'Notre histoire',
    component: Notre_histoire
  },
  {
    path: '/documents',
    name: 'Documents',
    component: Documents
  },
  {
    path: '/accompagnement-de-projet',
    name: 'Accompagnement de projet',
    component: Accompagnement_projet
  },
  {
    path: '/faire-un-don',
    name: 'Faire_un_don',
    component: Faire_un_don
  },
  {
    path: '/nous-recrutons',
    name: 'Nous_recrutons',
    component: Nous_recrutons
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/mentions-legales',
    name: 'MentionsLegales',
    component: MentionsLegales
  },
  {
    path: '/cgu',
    name: 'CGU',
    component: CGU
  },
  {
    path: '/admin',
    name: 'Admin',
    component: Admin
  },
  {
    path: '/admin/compte',
    name: 'AdminCompte',
    component: AdminCompte
  },
  {
    path: '/admin/destinations',
    name: 'AdminDestinations',
    component: AdminDestinations
  },
  {
    path: '/admin/media',
    name: 'AdminMedia',
    component: AdminMedia
  },
  {
    path: '/admin/recettes',
    name: 'AdminRecettes',
    component: AdminRecettes
  },
  {
    path: '/admin/recettes/:id',
    name: 'AdminRecettesEdit',
    component: AdminRecettesEdit
  },
  {
    path: '/admin/menus',
    name: 'AdminMenus',
    component: AdminMenus
  },
  {
    path: '/admin/liste-courses/edit',
    name: 'AdminListeCoursesEdit',
    component: AdminListeCoursesEdit
  },
  {
    path: '/admin/liste-courses',
    name: 'AdminListeCourses',
    component: AdminListeCourses
  },
  {
    path: '/admin/liste-courses/:id',
    name: 'AdminListeCourses',
    component: AdminListeCourses
  },
  {
    path: '/admin/utilisateurs',
    name: 'AdminUserList',
    component: AdminUserList
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/adhesion',
    name: 'AccountCreated',
    component: AccountCreated
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
