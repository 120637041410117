<template>
  <b-container>
    <b-row class="justify-content-md-center">
      <b-col lg=10 align="center">
        <h1 class="tb-title">Documents</h1>
        <div class="buttons-file-download">
          <b-button variant="outline-secondary" size="lg" href="assets/documents/Projet associatif Tim & Bastien.pdf" download><b-icon icon="file-earmark-text"/> Projet associatif</b-button>
          <b-button variant="outline-secondary" size="lg" href="assets/documents/Reglement interieur Tim & Bastien.pdf" download><b-icon icon="file-earmark-text"/> Règlement intérieur</b-button>
          <b-button variant="outline-secondary" size="lg" href="assets/documents/Statuts association Tim & Bastien.pdf" download><b-icon icon="file-earmark-ruled"/> Statuts</b-button>
        </div>
        <h2 class="tb-title-2">Rapports d'activités</h2>
        <h2 class="tb-title-3">Pour l'année 2023</h2>
        <div class="buttons-file-download">
          <b-button variant="outline-secondary" size="lg" href="assets/documents/rapport_activite/2023/Rapport d'activités 2023 - Tim & Bastien.pdf" download><b-icon icon="file-earmark-text"/> Rapport moral 2023</b-button>
          <b-button variant="outline-secondary" size="lg" href="assets/documents/rapport_activite/2023/Bilan financier 2023 - Tim & Bastien.pdf" download><b-icon icon="file-earmark-ruled"/> Bilan financier 2023</b-button>
        </div>
        <h2 class="tb-title-3">Pour la période février 2022 - décembre 2022</h2>
        <div class="buttons-file-download">
          <b-button variant="outline-secondary" size="lg" href="assets/documents/rapport_activite/2022/Rapport d'activités 2022 - Tim & Bastien.pdf" download><b-icon icon="file-earmark-text"/> Rapport moral 2022</b-button>
          <b-button variant="outline-secondary" size="lg" href="assets/documents/rapport_activite/2022/Bilan financier 2022 - Tim & Bastien.pdf" download><b-icon icon="file-earmark-ruled"/> Bilan financier 2022</b-button>
        </div>
        <h2 class="tb-title-3">Pour la période février 2021 - février 2022</h2>
        <div class="buttons-file-download">
          <b-button variant="outline-secondary" size="lg" href="assets/documents/rapport_activite/2021/Rapport Moral 2022 - Tim & Bastien.pdf" download><b-icon icon="file-earmark-text"/> Rapport moral 2021</b-button>
          <b-button variant="outline-secondary" size="lg" href="assets/documents/rapport_activite/2021/Bilan financier feb 2021 - feb 2022 Tim & Bastien.pdf" download><b-icon icon="file-earmark-ruled"/> Bilan financier 2021</b-button>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { is_phone } from '@/plugins/utils.js'

export default {
  name: 'Documents',
  data () {
    return {
      is_phone: is_phone,
    }
  },
  mounted () {
  },
}
</script>

<style scoped>
.buttons-file-download {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.buttons-file-download>* {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
</style>
