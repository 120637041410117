<template>
  <div class="tb-rounded-border" style="padding: 15px">
    <div v-if="user_data">
      <h4 class="tb-title-4">{{ user_data.firstname }} {{ user_data.lastname }}</h4>
      <b-form @submit="updateUserInfo">
        <p>Mail: {{ user_data.username }}</p>
        <b-form-group label="Nom :">
          <b-form-input
            v-model="form.lastname"
            :placeholder="user_data.lastname"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Prénom :">
          <b-form-input
            v-model="form.firstname"
            :placeholder="user_data.firstname"
            required
          ></b-form-input>
        </b-form-group>

        <div class="connect-btn">
          <b-button type="submit" variant="outline-primary" class="mt-2">Mettre à jour mes informations</b-button>
        </div>
        <p class="invalid-password" v-if="error_update_data.is_error">
          {{ error_update_data.msg }}
        </p>
      </b-form>

      <b-form @submit="updatePassword">
        <p>Modifiez votre mot de passe</p>
        <label>Ancien mot de passe :</label>
        <b-form-input type="password" aria-describedby="password-help-block" v-model="form_pass.old_password"></b-form-input>

        <label>Nouveau mot de passe :</label>
        <b-form-input type="password" aria-describedby="password-help-block" v-model="form_pass.password_1"></b-form-input>

        <label>Retappez le mot de passe :</label>
        <b-form-input type="password" aria-describedby="password-help-block" v-model="form_pass.password_2"></b-form-input>

        <div class="connect-btn">
          <b-button type="submit" variant="outline-primary" class="mt-2">Mettre à jour mon mot de passe</b-button>
        </div>
        <p class="invalid-password" v-if="error_password.is_error">
          {{ error_password.msg }}
        </p>
      </b-form>
      <div style="height: 6px"></div>
      <div class="logout-btn">
        <b-button variant="outline-danger" v-on:click="logout()">Se déconnecter</b-button>
        <b-button variant="outline-danger" v-on:click="remove_account()">Supprimer mon compte</b-button>
      </div>
      <p class="invalid-password" v-if="error.is_error">
        {{ error.msg }}
      </p>
    </div>
  </div>

</template>

<script>
import { api_call } from '@/plugins/utils.js'

export default {
  name: 'UserInfo',
  components: {
  },
  data() {
    return {
      user_data: null,
      form: null,
      form_pass: {
        old_password: "",
        password_1: "",
        password_2: "",
      },
      error_update_data: {
        is_error: false,
        msg: null,
      },
      error_password: {
        is_error: false,
        msg: null,
      },
      error: {
        is_error: false,
        msg: null,
      },
    }
  },
  mounted() {
    this.update_user_infos()
  },
  methods: {
    update_user_infos() {
      api_call()
        .get('/login/me')
        .then(response => {
          this.user_data = response.data
          if (this.form == null) {
            this.form = {
              firstname: this.user_data.firstname,
              lastname: this.user_data.lastname,
            }
          }
        })
    },
    logout() {
      this.$store.commit('LOGOUT_SUCCESS')
      window.history.back()
    },
    remove_account() {
      if (!window.confirm("Voulez-vous vraiment supprimer votre compte ?\nCette action est irréversible."))
        return
      api_call()
        .delete("/login/me")
        .then(response => {
          this.logout()
        })
        .catch(error => {
          this.error.is_error = true
          try {
            this.error.msg = error.response.data
          } catch (error) {
            this.error.msg = "Unexpected error"
          }
        })
    },
    updateUserInfo() {
      event.preventDefault()
      api_call()
        .put("/login", {
          new_firstname: this.form.firstname,
          new_lastname: this.form.lastname,
        })
        .then(response =>{
          this.error_update_data.is_error = false
          this.update_user_infos()
        })
        .catch(error => {
          this.error_update_data.is_error = true
          try {
            this.error_update_data.msg = error.response.data
          } catch (error) {
            this.error_update_data.msg = "Unexpected error"
          }
        })
    },
    updatePassword() {
      event.preventDefault()
      if (this.form_pass.password_1 == this.form_pass.password_2) {
        api_call()
          .put("/login/change_password", {
            old_password: this.form_pass.old_password,
            new_password: this.form_pass.password_1,
          })
          .then(response =>{
            this.error_password.is_error = false
          })
          .catch(error => {
            this.error_password.is_error = true
            try {
              this.error_password.msg = error.response.data
            } catch (error) {
              this.error_password.msg = "Unexpected error"
            }
          })
      }
      else {
        this.error_password.is_error = true
        this.error_password.msg = "Le mot de passe doit être le même"
      }
    },
  },
}
</script>

<style scoped>
.logout-btn {
  width: 100%;
  display: flex;
  justify-content: center;
}

.logout-btn>* {
  margin-left: 3px;
  margin-right: 3px;
}
</style>
