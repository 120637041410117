<template>
  <b-container>
    <b-row class="justify-content-md-center">
      <b-col lg=10 align="center">
        <h1 class="tb-title">Le food truck</h1>
        <img class="le-food-truck-photos" src="@/../public/assets/photos/new_food_truck_2.jpg">
        <p>Depuis septembre 2021, nous avons ouvert un food truck tenu par des étudiants, et à destination des étudiants.</p>
        <p>Dans ce food truck, nous cuisinons de délicieux plats où les légumes frais, locaux et bio sont mis en avant. Nos menus sont proposés à <b>3€</b>, soit uniquement les coûts en matières premières – et <b>1€</b> pour les étudiant.e.s boursier.e.s. Pour permettre à l'étudiant.e de refaire nos plats chez lui, toutes nos recettes sont disponibles <a href="/recettes">ici</a>.</p>

        <p>Véritable projet éducatif, notre équipe accompagne les étudiant.e.s dans leur transition alimentaire, celle de quitter le foyer familial. </p>

        <h3 class="tb-title-3">Le food truck, un lieu d'échange et de partage</h3>
        <p>Dès 10h et jusqu'en milieu d'après-midi, les étudiant.e.s peuvent y acheter des cookies et des cafés pendant leurs pauses. C'est souvent l'occasion de discuter pour mieux comprendre les difficultés de chacun.e à s'alimenter sainement. De quoi permettre d'améliorer nos actions et de les adapter à la spécificité du campus.</p>

        <h3 class="tb-title-3">Toucher de nouveaux publics</h3>
        <p>Pour toucher de nouveaux publics, nous organisons des évènements ludiques :  Open-Bar de carottes (bâtonnets de carottes et sauces faits maison mis à disposition gratuitement lors d'évènements sur le campus), Apple Day (journée où des pommes sont proposées gratuitement aux étudiant.e.s), Amphi-débat (échange avec les étudiant.e.s autour des questions de l'alimentation saine et responsable, et de l'engagement), campagne de communication autour du bien-manger, etc.</p>

        <h3 class="tb-title-3">Cibler les campus avec le plus de précarité alimentaire</h3>
        <p>Nous voulons concentrer nos actions sur les campus étudiants où il y en a le plus besoin. C'est pourquoi le modèle du food truck est tout à fait adapté. En restant 3 semaines sur un campus, notre food truck a pu se rendre sur les campus de Saint-Dié-Des-Vosges, Nancy et Metz. </p>
        <p>La recherche des emplacements est réalisée en partenariat avec l'<b>Université de Lorraine</b> qui compte plus de 49 sites universitaires.</p>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "Le_food_truck",
}
</script>

<style scoped>
.le-food-truck-photos {
  max-width: 80%;
}
@media only screen and (max-width: 768px) {  /* phones only */
  .le-food-truck-photos {
    max-width: 100%;
  }
}
</style>