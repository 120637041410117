<template>
  <div>
    <b-container>
      <b-row class="connect-row justify-content-md-center">
        <b-col lg=4>
          <newsletter/>
        </b-col>
      </b-row>
      <b-row class="connect-row justify-content-md-center" v-if="$store.state.is_connected">
        <b-col lg=4 v-if="$store.state.is_connected">
          <user-info/>
        </b-col>
      </b-row>
      <b-row class="connect-row justify-content-md-center" v-if="!$store.state.is_connected">
        <b-col lg=4>
          <connect/>
        </b-col>
      </b-row>
      <b-row class="connect-row justify-content-md-center" v-if="!$store.state.is_connected">
        <b-col lg=4>
          <create-account/>
        </b-col>
      </b-row>
      <admin-base v-if="$store.state.is_admin"/>
    </b-container>
  </div>
</template>

<script>
import Newsletter from '@/components/login/Newsletter.vue'
import Connect from '@/components/login/Connect.vue'
import CreateAccount from '@/components/login/CreateAccount.vue'
import UserInfo from '@/components/login/UserInfo.vue'
import AdminBase from '@/components/admin/AdminBase.vue'

export default {
  name: 'Login',
  components: {
    Newsletter,
    Connect,
    CreateAccount,
    UserInfo,
    AdminBase
  },
  data () {
    return {
    }
  },
  mounted () {
  },
}
</script>

<style scoped>
.connect-row {
  margin-top: 5px;
}
</style>
