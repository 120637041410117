<template>
  <div id="tb-stats">
    <h1>Quelques chiffres</h1>
    <p>+ de <b>{{ nb_repas_animation }}</b> repas vendus</p>
    <p><b>{{ nb_campus_animation }}</b> campus étudiants</p>
  </div>
</template>

<script>
import { api_call } from '@/plugins/utils.js'
import { gsap } from 'gsap';

export default {
  name: "Stats",
  data () {
    return {
      stats: null,
      nb_repas_real: 0,
      nb_repas_show: 0,
      nb_campus_real: 0,
      nb_campus_show: 0,
    }
  },
  mounted() {
    api_call()
      .get("/sumup/stats")
      .then(response => {
        this.stats = response.data
      })
  },
  created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      if (this.stats && this.nb_repas_real == 0) {
        var sts = document.getElementById("tb-stats")
        if (window.scrollY - (sts.offsetTop - sts.clientHeight) > 0) {
          this.nb_repas_real = Math.round(this.stats.nb_repas / 100) * 100
          this.nb_campus_real = this.stats.nb_campus
        }
      }
    },
  },
  computed: {
    nb_repas_animation: function() {
      return this.nb_repas_show.toFixed(0);
    },
    nb_campus_animation: function() {
      return this.nb_campus_show.toFixed(0);
    },
  },
  watch: {
    nb_repas_real: function(newValue) {
      gsap.to(this.$data, { duration: 1, nb_repas_show: newValue });
    },
    nb_campus_real: function(newValue) {
      gsap.to(this.$data, { duration: 1, nb_campus_show: newValue });
    },
  }
}
</script>

<style scoped>
#tb-stats {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
}
#tb-stats h1 {
  text-align: center;
}
#tb-stats p {
  margin-top: 3rem;
  font-size: var(--size-large-text);
  text-align: center;
}
</style>